@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO: Please import css from library instead (Issue is cra not supporting hover:cursor-default) */

.leaflet-container {
  z-index: 1;
}

/* .react-datepicker__input-container input */
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  padding: 0.625rem 1.25rem;
  color: #333;
  placeholder: #ccc;
  outline: none;
}

/* .react-datepicker__tab-loop */
.react-datepicker__tab-loop {
  position: absolute;
}

/* .react-datepicker-popper */
.react-datepicker-popper {
  position: absolute;
  top: 20px;
  z-index: 10;
  left: 0;
  width: 18rem;
  font-size: 0.875rem;
  transform: none;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.375rem 0.75rem;
}

/* .react-datepicker__month-container */
.react-datepicker__month-container {
  display: flex;
  flex-direction: column;
}

/* .react-datepicker__month */
.react-datepicker__month {
  display: flex;
  flex-direction: column;
}

/* .react-datepicker__current-month */
.react-datepicker__current-month {
  margin-left: 0.625rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

/* .react-datepicker__week */
.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

/* .react-datepicker__day-names */
.react-datepicker__day-names {
  display: flex;
  justify-content: space-around;
  color: #333;
  font-weight: 500;
  text-align: center;
  font-size: 0.875rem;
}

/* .react-datepicker__day-name */
.react-datepicker__day-name {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border-radius: 50%;
}

/* .react-datepicker__navigation */
.react-datepicker__navigation {
  position: absolute;
  top: 0.5rem;
}

/* .react-datepicker__navigation--previous */
.react-datepicker__navigation--previous {
  right: 1.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .react-datepicker__navigation--next */
.react-datepicker__navigation--next {
  right: 0.375rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .react-datepicker__day */
.react-datepicker__day {
  margin-bottom: 0.25rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  transition: color 0.3s ease, background-color 0.3s ease;
  color: #555;
  border-radius: 0.25rem;
  cursor: pointer;
}

/* .react-datepicker__day--disabled */
.react-datepicker__day--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: transparent;
}

/* .react-datepicker__day--outside-month */
.react-datepicker__day--outside-month {
  color: #ccc;
}

/* .react-datepicker__day--in-range */
.react-datepicker__day--in-range {
  background-color: #a6c7e3;
}

/* .react-datepicker__day--in-selecting-range */
.react-datepicker__day--in-selecting-range {
  background-color: #a6c7e3;
}

/* .react-datepicker__day--selecting-range-start */
.react-datepicker__day--selecting-range-start {
  background-color: #fff;
  border: 2px solid #007bff;
}

/* .react-datepicker__day--selecting-range-end */
.react-datepicker__day--selecting-range-end {
  background-color: #fff;
  border: 2px solid #007bff;
}

/* .react-datepicker__day--selected */
.react-datepicker__day--selected {
  background-color: #007bff;
  color: #fff;
}

/* .react-datepicker__day--range-start */
.react-datepicker__day--range-start {
  background-color: #007bff;
  color: #fff;
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* .react-datepicker__day--range-end */
.react-datepicker__day--range-end {
  background-color: #007bff;
  color: #fff;
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* .react-datepicker__aria-live */
.react-datepicker__aria-live {
  display: none;
}

/* .loading-spinner */
.loading-spinner {
  border-top-color: #007bff !important;
  animation: spin 2s linear infinite;
}

/* .PhoneInputInput */
.PhoneInputInput {
  border: 0;
  focus: ring 0;
  width: 4rem;
  cursor: default;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media print {
  @page {
    size: landscape;
  }
}
